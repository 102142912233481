import React, { useCallback, useEffect, useState } from 'react';
import { Option } from './select-component';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components, MenuProps } from 'react-select';
import type { GroupBase, OptionsOrGroups } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  nullsToEmpty,
  parseKeys,
  parseTemplate,
  useComponentQueries,
  useComponentVariables,
} from '../component-hooks';
import { get, isArray, isNil } from 'lodash';
import { ComponentRender } from '../component-render';
import logger from '../../../logger';

export type OptionType = {
  value: number;
  label: string;
};

export const ReactSelectAsync = ({ field, form, componentProps, ...props }) => {
  const { variables } = useComponentVariables(componentProps);
  const { queries, query, getPropertyValue } = useComponentQueries(
    componentProps,
    variables,
  );
  const [selectedValue, setSelectedValue] = useState(null);
  const [clearCacheTrigger, setClearCacheTrigger] = useState(false);
  const [componentPrefix, setComponentPrefix] = useState('');
  const [prefix, setPrefix] = useState('');

  useEffect(() => {
    const prefix = props?.options?.prefix?.toString()?.trim() ?? '';
    const variablesWithPrefix = { ...variables };
    if (componentProps?.props?.prefix) {
      variablesWithPrefix.prefix = componentProps.props.prefix;
    }
    setComponentPrefix(
      parseTemplate(prefix, {
        ...variablesWithPrefix,
      }) ?? '',
    );
  }, [props?.options]);

  useEffect(() => {
    const prefix = componentProps?.props?.prefix?.toString()?.trim() ?? '';
    setPrefix(parseTemplate(prefix, { ...variables }));
  }, [componentProps?.props]);

  const loadOptions = useCallback(
    async (
      search: string,
      prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
      { page },
    ) => {
      const { options: opt } = componentProps.props;

      if (!opt?.searchQuery?.name) {
        throw new Error('options.searchQuery.name is required');
      }
      if (!opt?.searchQuery?.path) {
        throw new Error('options.searchQuery.path is required');
      }

      const filter = parseTemplate(
        componentProps.props?.filter ?? '',
        {
          ...variables,
          ...componentProps?.context?.store,
          ...form.values,
        },
        prefix,
      );
      const result = await getPropertyValue(
        {
          fromQuery: opt?.searchQuery,
        },
        {}, // default value
        {
          ...form.values, // additional params
          // additional params
          search: search ?? '',
          skip: (page - 1) * 10,
          pageSize: 10,
          filter,
        },
      );

      const transformedOptions = result?.map((o: any) => ({
        label:
          parseTemplate(opt?.itemLabelTemplate, o) ??
          (typeof opt?.itemValueTemplate !== 'object'
            ? opt?.itemValueTemplate
              ? parseTemplate(opt?.itemValueTemplate, o)
              : o
            : ''),
        value: opt?.itemValueTemplate
          ? parseTemplate(opt?.itemValueTemplate, o)
          : o,
      }));
      return {
        options: transformedOptions ?? [],
        hasMore: transformedOptions.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    },
    [queries, componentProps],
  );

  const selectValue = useCallback(
    async (value: any) => {
      const { options: opt } = componentProps.props;
      const searchQuery = opt?.valueQuery?.name;
      const path = opt?.valueQuery?.path;

      if (!searchQuery && !opt.allowInlineCreate) {
        throw new Error('options.valueQuery.name is required');
      }
      if (!path && !opt.allowInlineCreate) {
        throw new Error('options.valueQuery.path is required');
      }

      try {
        let formValues = {
          ...form.values,
          selectedValue: value,
        };

        // baseName is deprecated
        if (componentProps?.props?.options?.baseName) {
          formValues = {
            ...formValues,
            [componentProps.props.options.baseName]: value,
          };
        }

        if (componentProps?.props?.options?.valueFieldName) {
          formValues = {
            ...formValues,
            [componentProps.props.options.valueFieldName]: value,
          };
        }

        // verify all params are present
        if (opt?.valueQuery?.params) {
          const missingParams = Object.keys(opt?.valueQuery?.params)?.filter(
            (param) => !get(formValues, `${componentPrefix}${param}`),
          );
          if (missingParams.length) {
            logger.warn(
              `Missing params for query ${searchQuery}: ${missingParams.join(
                ', ',
              )}`,
            );
            setSelectedValue(null);
            return; // missing params
          }
        }

        let item = value;

        if (opt?.valueQuery?.params) {
          let queryParams = parseTemplate(
            opt?.valueQuery?.params,
            formValues,
            componentPrefix,
          );
          if (
            Object.keys(opt?.valueQuery?.params).some((x) => x === 'filter')
          ) {
            queryParams.filter = componentProps?.props?.filter ?? '';
          }

          let result;
          let stringResults: string[] = [];
          if (opt?.allowMultiple) {
            result = [];
            Object.keys(queryParams).map((key) => {
              queryParams[key]
                .filter(
                  (param) =>
                    !opt.allowInlineCreate ||
                    (opt.allowInlineCreate && typeof param === 'number'),
                )
                .map((param) => {
                  result.push(
                    query(searchQuery, {
                      ...queryParams,
                      [key]: param.value ?? param[key] ?? param,
                    }),
                  );
                });

              if (opt.allowInlineCreate) {
                stringResults = queryParams[key]
                  .filter((param) => typeof param === 'string')
                  .map((param) => ({ [path]: { label: param, value: param } }));
              }
            });
            result = await Promise.all(result);
            result = [...result, ...stringResults];
            item = result.map((r) => get(r, path));
          } else {
            result = await query(searchQuery, queryParams);
            item = get(result, path);
          }
        }

        componentProps?.setItem(item);
        let tempSelectedValue = item;

        if (opt?.itemValueTemplate) {
          if (opt?.allowMultiple) {
            tempSelectedValue = item.map(
              (i) => parseTemplate(opt?.itemValueTemplate, i) ?? i,
            );
          } else {
            tempSelectedValue = parseTemplate(opt?.itemValueTemplate, item);
          }
        }

        // invoke onSelectValue action
        if (componentProps?.props?.onSelectValue && item) {
          // parse keys
          const onSelectValue = parseKeys(
            componentProps?.props?.onSelectValue,
            {
              ...variables,
              ...formValues,
              prefix: componentPrefix,
            },
          );

          const selectedItem = Object.fromEntries(
            Object.entries(item).filter((entry) => entry[0] !== '__typename'),
          );

          const path = componentPrefix.slice(0, -1);

          if (!componentProps?.props?.setOnlySpecifiedValues) {
            form.setFieldValue(path, {
              ...get(
                {
                  ...form.values,
                  ...componentProps?.context?.store?.[
                    componentProps?.variables?.formName
                  ],
                },
                path,
              ),
              ...nullsToEmpty(selectedItem),
            });
          }

          componentProps.context?.action(
            onSelectValue,
            {
              selectedValue: tempSelectedValue,
              selectedItem,
              ...componentProps?.variables,
            },
            {
              sender: field.name,
              formikContext: form,
            },
            componentPrefix,
          );
        }

        if (opt?.allowMultiple) {
          setSelectedValue(
            item
              ? item.map((i) => {
                  if (
                    i.hasOwnProperty('label') &&
                    i.hasOwnProperty('value') &&
                    i.label === i.value
                  )
                    return {
                      label: i.label,
                      value: i.value,
                    };
                  else
                    return {
                      label: opt?.itemLabelTemplate
                        ? parseTemplate(opt?.itemLabelTemplate, i)
                        : i,
                      value: opt?.itemValueTemplate
                        ? parseTemplate(opt?.itemValueTemplate, i)
                        : i,
                    };
                })
              : null,
          );
        } else {
          setSelectedValue(
            item
              ? {
                  label: opt?.itemLabelTemplate
                    ? parseTemplate(opt?.itemLabelTemplate, item)
                    : item,
                  value: opt?.itemValueTemplate
                    ? parseTemplate(opt?.itemValueTemplate, item)
                    : item,
                }
              : null,
          );
        }

        if (!item) {
          form.setFieldValue(field.name, undefined);
        }
      } catch (e) {
        console.error(`Query ${searchQuery} failed`, e);
      }
    },
    [queries, componentProps],
  );

  const selectValueAction = useCallback(
    async (action: any, data: any, latestStoreValues: any, source?: any) => {
      const value = parseTemplate(action?.selectValue, data);
      form.setFieldValue(field.name, value);
    },
    [selectValue],
  );

  const handleChange = useCallback(
    (selectedOption: any | null) => {
      setSelectedValue(selectedOption);
      let value;

      // tslint:disable-next-line:prefer-conditional-expression
      if (props?.options?.allowMultiple) {
        value = selectedOption ? selectedOption.map((v) => v.value) : [];
      } else {
        value = selectedOption ? selectedOption.value : '';
      }
      form.setFieldValue(field.name, value);

      if (
        props?.options?.onChange &&
        componentProps?.context?.action &&
        props?.value !== value
      ) {
        componentProps.context.action(
          props?.options?.onChange,
          {
            ...variables,
            ...componentProps?.context?.store,
            ...componentProps?.context?.store?.[variables?.formName],
            prefix: componentProps?.props?.prefix,
            value,
          },
          {
            sender: props.name,
            actions: props.actions,
            formikContext: form,
          },
        );
      }
    },
    [form, field.name, props],
  );

  const handleCreate = (inputValue: string) => {
    let newValue;
    if (props?.options?.allowMultiple) {
      if (selectedValue) {
        newValue = [...selectedValue, { label: inputValue, value: inputValue }];
      } else {
        newValue = [{ label: inputValue, value: inputValue }];
      }
    } else {
      newValue = { label: inputValue, value: inputValue };
    }
    setSelectedValue(newValue);
    handleChange(newValue);

    const formValues = {
      ...form.values,
      createdValue: inputValue,
    };

    // invoke onCreateValue action
    if (componentProps?.props?.onCreateValue && inputValue) {
      // parse keys
      const onCreateValue = parseKeys(componentProps?.props?.onCreateValue, {
        ...variables,
        ...formValues,
        prefix: componentPrefix,
      });

      componentProps.context?.action(
        onCreateValue,
        {
          createdValue: inputValue,
        },
        {
          sender: field.name,
          formikContext: form,
        },
        componentPrefix,
      );
    }
  };

  const onOptionTagClick = useCallback(
    (option: { label: string; value: string | number }) => {
      let clickedOptionTagData;
      if (componentProps?.item && isArray(componentProps.item)) {
        clickedOptionTagData = componentProps.item.find(
          (i: any) =>
            i[componentProps.props?.options?.valueFieldName]?.toString() ===
            option.value.toString(),
        );
      }

      if (
        componentProps?.props?.onOptionTagClick &&
        componentProps?.context?.action
      ) {
        componentProps.context.action(
          componentProps.props.onOptionTagClick,
          {
            ...variables,
            ...componentProps.context.store,
            ...componentProps.context.store?.[variables?.formName],
            item: { ...option, ...clickedOptionTagData },
          },
          {
            sender: props.name,
            actions: props.actions,
            formikContext: form,
          },
        );
      }
    },
    [props, componentProps, variables, form],
  );

  useEffect(() => {
    if (!isNil(field.value)) {
      selectValue(field.value);
    }
  }, [field.value, queries]);

  const Menu = useCallback(
    (menuProps: MenuProps) => {
      return (
        <components.Menu {...menuProps} className="prevent-drag async-menu">
          {menuProps.children}
          {componentProps?.props?.dropDownToolbar?.map((child, index) => {
            return !child.name?.includes('create') ||
              (child.name?.includes('create') &&
                props?.options?.allowCreate !== false) ? (
              <ComponentRender
                key={child.name ?? index + field.name}
                {...child}
                context={componentProps.context}
                variables={componentProps.variables}
                actions={{ selectValue: selectValueAction }}
              />
            ) : null;
          })}
        </components.Menu>
      );
    },
    [componentProps],
  );

  const CustomTagLabel = ({ data, innerProps }) => {
    return (
      <div
        key={data.value}
        style={{
          borderRadius: '2px',
          backgroundColor: '#e7e7e7',
          padding: '3px 3px 3px 8px',
          cursor: 'pointer',
        }}
        onClick={() => {
          onOptionTagClick(data);
        }}
        onMouseDown={(e) => e.stopPropagation()}
        {...innerProps}
      >
        {data.label}
      </div>
    );
  };

  return props?.options?.allowInlineCreate ? (
    <CreatableSelect
      {...field}
      isMulti={props?.options?.allowMultiple}
      isDisabled={componentProps?.props?.disabled}
      onChange={handleChange}
      value={selectedValue}
      options={props?.options?.items}
      onCreateOption={handleCreate}
      components={{
        MultiValueLabel: CustomTagLabel,
      }}
      classNamePrefix={'select'}
      openMenuOnClick={
        componentProps?.props?.options?.openMenuOnClick !== false
      }
      placeholder={
        props?.placeholder ?? componentProps?.props?.options?.placeholder
      }
    />
  ) : (
    <AsyncPaginate
      {...field}
      isMulti={props?.options?.allowMultiple}
      isClearable={props?.options?.isClearable ?? true}
      isDisabled={componentProps?.props?.disabled}
      onChange={handleChange}
      loadOptions={loadOptions}
      value={selectedValue}
      cacheUniqs={[clearCacheTrigger]}
      onMenuOpen={() => setClearCacheTrigger(!clearCacheTrigger)}
      components={{
        Menu,
        MultiValueLabel: CustomTagLabel,
      }}
      additional={{
        page: 1,
      }}
      classNamePrefix={'select'}
      openMenuOnClick={
        componentProps?.props?.options?.openMenuOnClick !== false
      }
      placeholder={
        props?.placeholder ?? componentProps?.props?.options?.placeholder
      }
    />
  );
};
