import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { InternalLayout } from '../components/internal-layout/internal-layout.component';

import { Helmet } from 'react-helmet';
import {
  localized,
  parseTemplate,
  toBoolean,
} from '../components/layout/component-hooks';
import { AppComponent } from './appComponent.component';
import { useEffect, useState, useCallback, useContext, Suspense } from 'react';
import { UiContextProvider } from '../context/uiContextProvider.provider';
import { ComponentRender } from '../components/layout/component-render';
import UiContext from '../context/uiContext';
import { useStore } from 'effector-react';
import {
  currentUserStore,
  getCurrentUserFx,
} from '../../currentUser/currentUser.store';
import { APP_MODULE_DEBUG } from '../../../properties';
import { organizationsStore } from '../../organization/organization.store';
import { connectToSSE } from '../../aiChat/aiChat.store';

// Utility function to convert any potential string numbers to integers
const convertToInt = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // if starts with 0, it's a string
      if (obj[key] !== '0' && obj[key].toString().startsWith('0')) {
        continue;
      }
      const parsedInt = Number(obj[key]);
      if (Math.abs(parsedInt) > 1e21) {
        // If the value is a number with exponent, skip it
        continue;
      }
      // If the parsed integer is a valid number and not NaN, assign it back to the object
      if (!isNaN(parsedInt)) {
        obj[key] = parsedInt;
      }
    }
  }
  return obj;
};
export const DebugView = ({ variables }) => {
  const contextValue = useContext(UiContext);
  const [debugView, setDebugView] = useState(false);

  useEffect(() => {
    const debug = parseTemplate('{{ localStorage debug }}', variables);
    setDebugView(toBoolean(debug));
  }, [variables]);

  if (!debugView) return null;
  return (
    <pre>
      <h3>Store</h3>
      {JSON.stringify(contextValue.storeValues, null, 2)}
      <h3>Variables</h3>
      {JSON.stringify(variables, null, 2)}
    </pre>
  );
};

export const AppComponentScreen = ({ appRoute }) => {
  const [variables, setVariables] = useState({
    // isDevelopment: true, // TODO: remove this
  } as any);
  const [component, setComponent] = useState(null as any);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [rootAppComponent, setRootAppComponent] = useState(null as any);

  // get current user from store
  const currentUser = useStore(currentUserStore);

  // load current organization
  const { currentOrganization } = useStore(organizationsStore);

  // connect to SSE
  useEffect(() => {
    connectToSSE(currentOrganization.uniqueId);
  }, [currentOrganization]);

  useEffect(() => {
    if (!currentUser) {
      // load current user
      getCurrentUserFx();
    } else {
      // set currentUsers to variables
      setVariables({
        ...variables,
        currentUser: {
          fullName: currentUser.fullName,
          ...currentUser,
        },
      });
    }
  }, [currentUser]);

  const params = useParams();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const queryStrings = Object.fromEntries(urlParams.entries());
    const convertedQueryStrings = convertToInt({ ...params, ...queryStrings });

    const newVariables = {
      ...params,
      ...convertedQueryStrings,
      currentUser,
      // isDevelopment: true, // TODO: remove this
    };

    if (JSON.stringify(newVariables) !== JSON.stringify(variables))
      setVariables(newVariables);

    if (appRoute?.component && appRoute?.component !== component)
      setComponent(appRoute?.component);
  }, [params, appRoute]);

  const setParams = useCallback(
    (newParams: any, options) => {
      const oldParams = Object.fromEntries(searchParams.entries());
      // set params to query string
      setSearchParams({ ...oldParams, ...newParams }, options);
    },
    [location, setSearchParams, searchParams],
  );

  const onComponentChange = useCallback(
    (loadedComponent: any) => {
      setRootAppComponent(loadedComponent);
    },
    [setRootAppComponent],
  );

  return (
    <UiContextProvider
      setParams={setParams}
      additionalActions={appRoute.props?.additionalActions}
    >
      {(props) => (
        <Suspense fallback={<div>Loading...</div>}>
          <InternalLayout
            title={parseTemplate(
              localized(appRoute.props?.title),
              props?.context?.store,
            )}
            createLabel={'AppModule'}
            version="v2"
            variables={rootAppComponent?.variables}
            toolbar={rootAppComponent?.props?.toolbar?.map((child, index) => {
              return (
                <ComponentRender
                  key={child.name}
                  {...child}
                  context={rootAppComponent.context}
                  variables={rootAppComponent.variables}
                />
              );
            })}
          >
            <Helmet>
              <title>
                {parseTemplate(
                  localized(appRoute.props?.title),
                  props?.context?.store,
                )}
              </title>
            </Helmet>
            {component && (
              <AppComponent
                component={component}
                onComponentChange={onComponentChange}
                variables={variables}
              />
            )}
            <DebugView variables={variables} />
          </InternalLayout>
        </Suspense>
      )}
    </UiContextProvider>
  );
};
