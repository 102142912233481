import React from 'react';
import { useStore } from 'effector-react';
import { dialogStore, closeDialog } from '../../dialog.store';
import { $aiChatOpen } from '../../../aiChat/aiChat.store';

export const DialogsHolder = () => {
  const { dialogs } = useStore(dialogStore);
  const isAIChatOpen = useStore($aiChatOpen);

  return (
    <div className={`modal-container ${isAIChatOpen ? 'chat-open' : ''}`}>
      {dialogs.map((dialog, index) => {
        return (
          <dialog.modal
            key={index}
            {...dialog.props}
            className={`${dialog.props.className || ''} ${
              isAIChatOpen ? 'chat-open' : ''
            }`}
            close={(result) => {
              dialog._resolve(result);
              closeDialog(dialog);
            }}
          />
        );
      })}
    </div>
  );
  // return <div>{dialogs.map((dialog) => {return dialog})}</div>;
};
