import { parseTemplate } from '../../components/layout/component-hooks';
import {
  setSuggestedEntities,
  setQuickQuestions,
  Entity,
} from '../../../aiChat/aiChat.store';
import logger from '../../logger';

export const setAIContextAction = ({
  actionProps,
  data,
  latestStoreValues,
  source,
  onAction,
}) => {
  try {
    // Process entities if provided
    if (actionProps.setAIContext.entities) {
      const parsedEntities = actionProps.setAIContext.entities.map((entity) => {
        // Parse template values for each entity property
        const parsedEntity: Entity = {
          entityId: parseTemplate(entity.entityId, {
            ...latestStoreValues,
            ...data,
          }),
          entityName: parseTemplate(entity.entityName, {
            ...latestStoreValues,
            ...data,
          }),
          entityType: parseTemplate(entity.entityType, {
            ...latestStoreValues,
            ...data,
          }),
          displayText: parseTemplate(entity.displayText, {
            ...latestStoreValues,
            ...data,
          }),
        };
        return parsedEntity;
      });

      // Set the suggested entities in the AI chat store
      setSuggestedEntities(parsedEntities);
    }

    // Process quick questions if provided
    if (actionProps.setAIContext.quickQuestions) {
      const parsedQuestions = actionProps.setAIContext.quickQuestions.map(
        (question) =>
          parseTemplate(question, {
            ...latestStoreValues,
            ...data,
          }),
      );

      // Set the quick questions in the AI chat store
      setQuickQuestions(parsedQuestions);
    }

    // Execute onSuccess action if provided
    if (actionProps.setAIContext.onSuccess) {
      onAction(
        actionProps.setAIContext.onSuccess,
        data,
        latestStoreValues,
        source,
      );
    }

    return true;
  } catch (error) {
    logger.error('Error setting AI context:', error);

    // Execute onError action if provided
    if (actionProps.setAIContext.onError) {
      onAction(
        actionProps.setAIContext.onError,
        { error, ...data },
        latestStoreValues,
        source,
      );
    }

    return false;
  }
};
