import {
  createStore,
  createEvent,
  createEffect,
  combine,
  sample,
  attach,
} from 'effector';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { getBearerToken } from '../api/api.service';
import logger from '../common/logger';

import { AI_CHAT_API_URL } from '../../properties';
// Yup Validation Schemas
const ThreadSchema = yup.object({
  id: yup.string().optional(),
  org_id: yup.string().optional(),
  user_id: yup.string().optional(),
  topic: yup.string().optional(),
  title: yup.string().optional(),
  created_at: yup.string().optional(),
  updated_at: yup.string().optional(),
});

const MessageSchema = yup.object({
  id: yup.string().optional(),
  thread_id: yup.string().optional(),
  user_id: yup.string().optional(),
  content: yup
    .array(
      yup.object().shape({
        type: yup.string().required(),
        text: yup.lazy((value) =>
          typeof value === 'string'
            ? yup.string().required()
            : yup
                .object({
                  value: yup.string().required(),
                })
                .required(),
        ),
        entity: yup
          .object({
            name: yup.string().optional(),
            type: yup.string().optional(),
            id: yup.string().optional(),
            entityId: yup.string().optional(),
            entityName: yup.string().optional(),
            entityType: yup.string().optional(),
          })
          .optional(),
      }),
    )
    .required(),
  sender_type: yup.string().oneOf(['user', 'assistant', 'system']).optional(),
  created_at: yup.string().optional(),
  session_id: yup.string().optional(),
});

const StreamChunkSchema = yup.object({
  id: yup.string().optional(),
  thread_id: yup.string().optional(),
  object: yup.string().optional(),
  created: yup.number().optional(),
  choices: yup
    .array(
      yup.object({
        index: yup.number().optional(),
        delta: yup
          .object({
            content: yup.string().optional(),
            role: yup.string().optional(),
          })
          .required(),
        finish_reason: yup.string().nullable().optional(),
      }),
    )
    .optional(),
});

// Types
export type Message = yup.InferType<typeof MessageSchema>;
export type Thread = yup.InferType<typeof ThreadSchema>;
export type StreamChunk = yup.InferType<typeof StreamChunkSchema>;

// Paginated response type
type PaginatedThreadsResponse = {
  items: Thread[];
  total: number;
  offset: number;
  limit: number;
  has_more: boolean;
};

// Thread pagination interface
interface ThreadPagination {
  offset: number;
  limit: number;
  total: number;
  has_more: boolean;
  loading: boolean;
}

// Define the entity type to match what's used in the component
export type Entity = {
  displayText: string;
  entityName: string;
  entityType: string;
  entityId: string;
};

// Session Management
const sessionId = uuidv4(); // Generate a static session ID once per app instance

const getSessionId = () => {
  return sessionId;
};

// API Effects with Validation
const searchThreadsFx = createEffect<
  { orgId: string; offset?: number; limit?: number; query?: string },
  PaginatedThreadsResponse
>(async ({ orgId, offset = 0, limit = 10, query = '' }) => {
  const searchParams = new URLSearchParams();
  searchParams.append('offset', offset.toString());
  searchParams.append('limit', limit.toString());
  const token = getBearerToken();

  if (query) {
    searchParams.append('query', query);
  }

  const url = `${AI_CHAT_API_URL}/api/v1/orgs/${orgId}/threads?${searchParams.toString()}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to search threads');
  }

  return response.json();
});

const loadThreadMessagesFx = createEffect<
  { orgId: string; threadId: string },
  Message[]
>(async ({ orgId, threadId }) => {
  const token = getBearerToken();
  const response = await fetch(
    `${AI_CHAT_API_URL}/api/v1/orgs/${orgId}/threads/${threadId}/messages`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  const data = await response.json();
  return data.items.sort((a: Message, b: Message) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });
});

const sendMessageFx = createEffect<
  {
    orgId: string;
    threadId?: string;
    content: {
      type: string;
      text?: { value: string };
      entity?: {
        name: string;
        entityId: string;
        entityName: string;
        entityType: string;
      };
    }[];
  },
  Message
>(async ({ orgId, threadId, content }) => {
  const token = getBearerToken();
  const sessionId = getSessionId();
  const messageId = uuidv4();

  // Create optimistic message
  const optimisticMessage: Message = {
    id: messageId,
    message_id: messageId,
    thread_id: threadId || '',
    content,
    sender_type: 'user',
    created_at: new Date().toISOString(),
    session_id: sessionId,
  };

  // Add optimistic message to store
  addOptimisticMessage(optimisticMessage);

  try {
    const response = await fetch(
      `${AI_CHAT_API_URL}/api/v1/orgs/${orgId}/threads/${
        threadId || ''
      }/messages`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          content,
          sender_type: 'user',
          session_id: sessionId,
          message_id: messageId,
        }),
      },
    );

    const data = await response.json();
    setWritingStatus(true); // Set writing status after message is sent
    return data;
  } catch (error) {
    // In case of error, we might want to remove the optimistic message
    // This would require additional error handling in the $messages store
    throw error;
  }
});

// Events and Stores
const toggleAIChat = createEvent<void>();
const setAIChatOpen = createEvent<boolean>();
const updateMessage = createEvent<{ id: string; newContent: string }>();
const messageReceived = createEvent<StreamChunk>();
const streamCompleted = createEvent<string>();
const resetCurrentThread = createEvent();
const createNewThread = createEvent();

// Add events for thread history
const toggleThreadHistory = createEvent<void>();
const setThreadHistoryOpen = createEvent<boolean>();
const searchThreadQuery = createEvent<string>();
const selectThread = createEvent<Thread>();
const loadMoreThreads = createEvent<void>();
const resetThreadPagination = createEvent<void>();

// Add new events for optimistic updates
const addOptimisticMessage = createEvent<Message>();
const setWritingStatus = createEvent<boolean>();

// Add events for suggested entities
const setSuggestedEntities = createEvent<Entity[]>();
const addSuggestedEntity = createEvent<Entity>();
const clearSuggestedEntities = createEvent<void>();

// Add events for quick questions
const setQuickQuestions = createEvent<string[]>();

// Add events for SSE connection status
const setSSEConnectionStatus = createEvent<
  'connected' | 'disconnected' | 'reconnecting' | 'error'
>();
const setSSEError = createEvent<{ status?: number; message: string }>();

// Add store for SSE connection status
const $sseConnectionStatus = createStore<
  'connected' | 'disconnected' | 'reconnecting' | 'error'
>('disconnected').on(setSSEConnectionStatus, (_, status) => status);

// Add store for SSE error information
const $sseError = createStore<{ status?: number; message: string } | null>(null)
  .on(setSSEError, (_, error) => error)
  .reset(setSSEConnectionStatus);

// Add pagination store
const $threadPagination = createStore<ThreadPagination>({
  offset: 0,
  limit: 10,
  total: 0,
  has_more: false,
  loading: false,
})
  .on(searchThreadsFx.pending, (state, pending) => ({
    ...state,
    loading: pending,
  }))
  .on(searchThreadsFx.doneData, (state, response) => ({
    offset: response.offset,
    limit: response.limit,
    total: response.total,
    has_more: response.has_more,
    loading: false,
  }))
  .on(resetThreadPagination, () => ({
    offset: 0,
    limit: 10,
    total: 0,
    has_more: false,
    loading: false,
  }));

const $threads = createStore<Thread[]>([])
  .on(searchThreadsFx.doneData, (state, response) => {
    // If offset is 0, replace threads, otherwise append
    if (response.offset === 0) {
      return response.items;
    } else {
      return [...state, ...response.items];
    }
  })
  .on(searchThreadsFx.fail, (state) => {
    logger.error('Failed to load threads');
    return state;
  })
  .on(createNewThread, () => []);

// Add thread history state
const $threadHistoryView = createStore<boolean>(false)
  .on(toggleThreadHistory, (state) => !state)
  .on(setThreadHistoryOpen, (_, value) => value)
  .reset(toggleAIChat); // Reset thread history view when closing chat

// Add thread search query state
const $threadSearchQuery = createStore<string>('').on(
  searchThreadQuery,
  (_, query) => query,
);

// Filtered threads based on search query
const $filteredThreads = combine(
  $threads,
  $threadSearchQuery,
  (threads, query) => {
    if (!query) return threads;
    const lowerQuery = query.toLowerCase();
    return threads.filter(
      (thread) =>
        thread.title?.toLowerCase().includes(lowerQuery) ||
        false ||
        thread.topic?.toLowerCase().includes(lowerQuery) ||
        false,
    );
  },
);

// Create an empty thread with an ID
const createEmptyThread = (): Thread => ({
  id: uuidv4(),
  org_id: '',
  title: 'New Chat',
  topic: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  user_id: '',
});

const $currentThread = createStore<Thread | null>(createEmptyThread())
  .on(sendMessageFx.doneData, (state, response) => {
    // If we have a state and the threadId matches, return the current state
    // otherwise, create a minimal thread with the ID
    if (state && state.id === response.thread_id) {
      return state;
    }
    const newState = {
      ...createEmptyThread(),
      id: response.thread_id,
      created_at: response.created_at,
    };

    // Save thread ID for current user if available
    if (newState.id && response.user_id) {
      saveLastThreadId(newState.id, response.user_id);
    }

    return newState;
  })
  .on(createNewThread, () => createEmptyThread())
  .on(selectThread, (_, thread) => {
    // When selecting a thread, save it to localStorage if we have user ID
    if (thread.id && thread.user_id) {
      saveLastThreadId(thread.id, thread.user_id);
    }
    return thread;
  })
  .reset(resetCurrentThread);

const $messages = createStore<Message[]>([])
  .on(loadThreadMessagesFx.doneData, (_, messages) => messages)
  .on(addOptimisticMessage, (state, message) => [...state, message])
  .on(sendMessageFx.doneData, (state, message) => {
    // Replace optimistic message with real one
    return state.map((msg) => (msg.id === message.id ? message : msg));
  })
  .on(updateMessage, (state, { id, newContent }) =>
    state.map((msg) =>
      msg.id === id
        ? { ...msg, content: [{ type: 'text', text: { value: newContent } }] }
        : msg,
    ),
  );

const $aiChatOpen = createStore<boolean>(false)
  .on(toggleAIChat, (state) => !state)
  .on(setAIChatOpen, (_, value) => value);

// Update streaming message store
const $streamingMessage = createStore<{
  content: string;
  thread_id: string | null;
  id: string;
  created_at: string;
}>({ content: '', thread_id: null, id: '', created_at: '' })
  .on(messageReceived, (state, chunk) => {
    const newState = {
      ...state,
      id: chunk.id,
      thread_id: chunk.thread_id,
      // convert chunk.created to date from Linux timestamp
      created_at:
        state.created_at || new Date(chunk.created * 1000).toISOString(),
      content: state.content + (chunk.choices[0].delta.content || ''),
    };
    return newState;
  })
  .reset(streamCompleted);

// Enhanced SSE Handler with Validation and Reconnection
const connectToSSE = (orgId: string) => {
  let eventSource: EventSource | null = null;
  let reconnectAttempt = 0;
  let reconnectTimeout: ReturnType<typeof setTimeout> | null = null;
  let isManualClose = false;

  const connect = () => {
    if (eventSource) {
      eventSource.close();
    }

    try {
      const token = getBearerToken();

      if (!token) {
        setSSEError({ message: 'No authentication token available' });
        setSSEConnectionStatus('error');
        return;
      }

      const sessionId = getSessionId();
      eventSource = new EventSource(
        `${AI_CHAT_API_URL}/api/v1/orgs/${orgId}/stream?session_id=${sessionId}&auth=Bearer ${token}`,
      );

      setSSEConnectionStatus('reconnecting');

      eventSource.onopen = () => {
        reconnectAttempt = 0;
        setSSEConnectionStatus('connected');
      };

      const messageHandler = (event: MessageEvent) => {
        try {
          const data = JSON.parse(event.data);
          if (data.object === 'chat.completion.chunk') {
            messageReceived(data);
          } else if (data.object === 'chat.completion.done') {
            streamCompleted(data.id);
            if ($currentThread.getState()) {
              loadThreadMessagesFx({
                orgId,
                threadId: $currentThread.getState()!.id,
              });
            }
          }
        } catch (error) {
          logger.error('Invalid SSE data:', error);
        }
      };

      eventSource.addEventListener('message', messageHandler);

      eventSource.onerror = (error) => {
        // Try to detect 401 errors
        if (eventSource.readyState === EventSource.CLOSED) {
          const errorEvent = error as ErrorEvent;
          // Check if we can access status through the error event
          // Note: browsers might not expose the status code directly
          if (errorEvent.target && 'status' in errorEvent.target) {
            const status = (errorEvent.target as any).status;
            if (status === 401) {
              setSSEError({
                status: 401,
                message: 'Authentication token expired',
              });
              setSSEConnectionStatus('error');
              closeConnection();
              return;
            }
          }
        }

        if (!isManualClose && eventSource.readyState === EventSource.CLOSED) {
          setSSEConnectionStatus('disconnected');
          scheduleReconnect();
        }
      };
    } catch (error) {
      logger.error('SSE connection error:', error);
      setSSEConnectionStatus('error');
      scheduleReconnect();
    }
  };

  const scheduleReconnect = () => {
    if (isManualClose) return;

    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout);
    }

    // Exponential backoff with max delay of ~30 seconds
    const delay = Math.min(1000 * Math.pow(1.5, reconnectAttempt), 30000);
    reconnectAttempt++;

    logger.info(
      `SSE reconnect scheduled in ${delay}ms (attempt ${reconnectAttempt})`,
    );
    reconnectTimeout = setTimeout(connect, delay);
  };

  const closeConnection = () => {
    isManualClose = true;

    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout);
      reconnectTimeout = null;
    }

    if (eventSource) {
      eventSource.close();
      eventSource = null;
    }

    setSSEConnectionStatus('disconnected');
  };

  // Initial connection
  connect();

  // Return a cleanup function
  return closeConnection;
};

// Error Handling
sendMessageFx.fail.watch(({ error }) => {
  logger.error('Message send failed:', error);
  // Add UI error handling here
});

loadThreadMessagesFx.fail.watch(({ error }) => {
  logger.error('Message load failed:', error);
});

const $messagesList = combine(
  $messages,
  $streamingMessage,
  (messages, streaming) => {
    const allMessages = [
      ...messages,
      ...(streaming.content
        ? [
            {
              id: streaming.id,
              thread_id:
                streaming.thread_id || $currentThread.getState()?.id || '',
              content: [{ type: 'text', text: { value: streaming.content } }],
              sender_type: 'assistant',
              created_at: new Date().toISOString(),
            },
          ]
        : []),
    ];

    // Remove sorting and maintain natural order
    return allMessages;
  },
);

// Create a store for suggested entities
const $suggestedEntities = createStore<Entity[]>([])
  .on(setSuggestedEntities, (_, entities) => entities)
  .on(addSuggestedEntity, (state, entity) => {
    // Check if entity already exists to avoid duplicates
    const exists = state.some((e) => e.entityId === entity.entityId);
    if (!exists) {
      return [...state, entity];
    }
    return state;
  })
  .on(clearSuggestedEntities, () => [])
  .reset(resetCurrentThread)
  .reset(createNewThread);

// Create a store for quick questions
const $quickQuestions = createStore<string[]>([]).on(
  setQuickQuestions,
  (_, questions) => questions,
);

// Add events and effects for thread persistence
const loadThreadDetailsFx = createEffect<
  { orgId: string; threadId: string },
  Thread
>(async ({ orgId, threadId }) => {
  const token = getBearerToken();
  const response = await fetch(
    `${AI_CHAT_API_URL}/api/v1/orgs/${orgId}/threads/${threadId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to load thread details');
  }

  return response.json();
});

// Helper to save thread ID to localStorage
const saveLastThreadId = (threadId: string, userId: string) => {
  try {
    localStorage.setItem(`aiChat_lastThreadId_${userId}`, threadId);
  } catch (error) {
    logger.error('Failed to save last thread ID to localStorage:', error);
  }
};

// Helper to get last thread ID from localStorage
const getLastThreadId = (userId: string): string | null => {
  try {
    return localStorage.getItem(`aiChat_lastThreadId_${userId}`);
  } catch (error) {
    logger.error('Failed to get last thread ID from localStorage:', error);
    return null;
  }
};

// Initialize last viewed thread
const initLastViewedThreadFx = createEffect<
  { orgId: string; userId: string },
  Thread | null
>(async (params) => {
  const lastThreadId = getLastThreadId(params.userId);
  if (!lastThreadId) {
    return null;
  }

  try {
    return await loadThreadDetailsFx({
      orgId: params.orgId,
      threadId: lastThreadId,
    });
  } catch (error) {
    logger.error('Failed to load last viewed thread:', error);
    return null;
  }
});

// Update the current thread based on initialization
sample({
  clock: initLastViewedThreadFx.doneData,
  filter: (thread) => thread !== null,
  target: $currentThread,
});

// Export interface
export {
  $threads,
  $currentThread,
  $messagesList,
  $threadHistoryView,
  $threadSearchQuery,
  $filteredThreads,
  $threadPagination,
  $suggestedEntities,
  $quickQuestions,
  searchThreadsFx,
  loadThreadMessagesFx,
  sendMessageFx,
  connectToSSE,
  resetCurrentThread,
  toggleAIChat,
  setAIChatOpen,
  $aiChatOpen,
  updateMessage,
  createNewThread,
  setWritingStatus,
  toggleThreadHistory,
  setThreadHistoryOpen,
  searchThreadQuery,
  selectThread,
  loadMoreThreads,
  resetThreadPagination,
  setSuggestedEntities,
  addSuggestedEntity,
  clearSuggestedEntities,
  setQuickQuestions,
  $sseConnectionStatus,
  $sseError,
  setSSEConnectionStatus,
  loadThreadDetailsFx,
  initLastViewedThreadFx,
};
