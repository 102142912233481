import { useEffect, useState } from 'react';
import { columnBuilder } from './column';
import { IRowRender, rowBuilder } from './row';
import { ITableView, ITableViewProps, tableView } from './tableView';

export const useTable = ({
  options,
  onRowClick,
  dataGridStore,
  formikContext,
}) => {
  const [columnDefinitions, setColumnDefinitions] = useState([]);
  const [tableInstance, setTableInstance] = useState({
    getTableProps: () => {
      return {
        className: 'table',
      };
    },
  });
  const [data, setData] = useState([]);
  const [rows, setRows] = useState<IRowRender[]>([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState<ITableView | undefined>();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    // create rows from data
    const dataRows = data?.map((row, index) => {
      return rowBuilder({
        data: row,
        index,
        colProps: {},
        rowProps: {
          style: { cursor: 'pointer' },
          onClick: (e) => {
            if (onRowClick) {
              onRowClick(view?.onRowClick ?? options.onRowClick, row);
            }
          },
          onEditClick: () => {
            if (options.onEditClick) {
              onRowClick(options.onEditClick, row);
            }
          },
        },
        parent: null, // parent
        viewName: 'data', // viewName
        dataGridStore,
        formikContext,
      });
    });

    setRows(dataRows);
  }, [data, dataGridStore]);

  // useEffect(() => {
  //   const cols = columnDefinitions.map((column) => {
  //     return columnBuilder({
  //       ...column,
  //     });
  //   });
  //   view?.setColumns(cols);
  // }, [columnDefinitions]);

  // useEffect(() => {
  //   if (view?.config.viewColumns) {
  //     const selectedCols = [];

  //     view?.config.viewColumns?.forEach((viewColumn: any) => {
  //       const colDef = columnDefinitions?.find(
  //         (c) => c.name === viewColumn?.name,
  //       );

  //       selectedCols.push(
  //         columnBuilder({
  //           ...colDef,
  //           name: viewColumn?.name ?? colDef?.name,
  //           path: viewColumn?.path ?? colDef?.path,
  //           label: viewColumn?.label ?? colDef?.label,
  //           type: viewColumn?.type ?? colDef?.type,
  //           editor: viewColumn?.editor ?? colDef?.editor,
  //           showAs: viewColumn?.showAs ?? colDef?.showAs,
  //           excludeFromQuery:
  //             viewColumn?.excludeFromQuery ?? colDef?.excludeFromQuery,
  //           isHidden: viewColumn?.isHidden ?? colDef?.isHidden,
  //           enableEdit: view.enableEdit,
  //         }),
  //       );
  //     });
  //     view?.setColumns(selectedCols);
  //   }
  // }, [view, columnDefinitions]);

  const refreshView = () => {
    setView((state) => {
      return { ...state };
    });
  };

  const setCurrentView = (viewProps: ITableViewProps) => {
    setView(
      tableView(
        {
          ...viewProps,
          storeView: (v: ITableView) => {
            setView(v);
          },
        },
        () => {
          refreshView();
        },
      ),
    );
  };

  return {
    columns: columnDefinitions,
    rows,
    data,
    setData,
    loading,
    pagination,
    tableInstance,
    setCurrentView,
    view,
  };
};
