import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faPaperPlane,
  faUpload,
  faPlus,
  faEdit,
  faCopy,
  faTimes,
  faHistory,
  faSearch,
  faArrowLeft,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { useStore } from 'effector-react';
import {
  $messagesList,
  sendMessageFx,
  $currentThread,
  connectToSSE,
  updateMessage,
  createNewThread,
  toggleAIChat,
  $threads,
  $threadHistoryView,
  $filteredThreads,
  $threadSearchQuery,
  $threadPagination,
  searchThreadsFx,
  loadThreadMessagesFx,
  toggleThreadHistory,
  searchThreadQuery,
  selectThread,
  resetThreadPagination,
  Thread,
  $suggestedEntities,
  Entity,
  clearSuggestedEntities,
  $quickQuestions,
} from '../../../../aiChat/aiChat.store';
import { organizationsStore } from '../../../../organization/organization.store';
import logger from '../../../logger';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { queryGraphQlFx } from '../../../../graphql/graphql.store';

interface AIChatProps {
  currentContext?: { type: string; id: string } | null;
  onFileUpload?: (file: File, threadId?: string) => Promise<void>;
  onCreateNewThread?: (params: {
    context?: { type: string; id: string } | null;
    messages?: any[];
    parentThreadId?: string;
  }) => Promise<void>;
}

// Create a ThreadItem component to display each thread item
const ThreadItem = React.memo(
  ({
    thread,
    onSelect,
  }: {
    thread: Thread;
    onSelect: (thread: Thread) => void;
  }) => {
    const handleClick = useCallback(
      (e) => {
        e.preventDefault();
        onSelect(thread);
      },
      [thread, onSelect],
    );

    const formattedDate = new Date(thread.created_at).toLocaleString();

    return (
      <a
        href="#"
        className="list-group-item list-group-item-action py-3"
        onClick={handleClick}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="mb-1 text-truncate">
            {thread.title || 'Untitled Thread'}
          </h6>
          <small className="text-muted">{formattedDate}</small>
        </div>
        <p className="mb-1 small text-truncate">
          {thread.topic || 'No topic available'}
        </p>
      </a>
    );
  },
);

const MessageEditButton = ({ messageId, onEdit }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onEdit(messageId);
  };

  return (
    <button
      type="button"
      className="btn btn-link btn-sm text-muted p-1"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faEdit} size="sm" />
    </button>
  );
};

const MessageCopyButton = ({ message, getContent }) => {
  const handleClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(getContent(message));
  };

  return (
    <button
      type="button"
      className="btn btn-link btn-sm text-muted p-1"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faCopy} size="sm" />
    </button>
  );
};

const EditSaveButton = ({ messageId, onSave }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onSave(messageId);
  };

  return (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleClick}
    >
      Save
    </button>
  );
};

const QuickQuestionButton = ({ question, onClick }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(question);
  };

  return (
    <button
      type="button"
      className="btn btn-outline-secondary p-1 pl-2 pr-2 m-1 text-truncate quick-question-btn"
      onClick={handleClick}
      title={question}
    >
      {question}
    </button>
  );
};

// Create a LoadMoreButton component to handle loading more threads
const LoadMoreButton = React.memo(
  ({ onClick, loading }: { onClick: () => void; loading: boolean }) => {
    return (
      <button
        className="btn btn-outline-secondary w-100 my-2"
        onClick={onClick}
        disabled={loading}
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} className="mr-2" />
        )}
        Load More
      </button>
    );
  },
);

const AIChat: React.FC<AIChatProps> = ({
  currentContext = null,
  onFileUpload,
  onCreateNewThread,
}) => {
  const messages = useStore($messagesList);
  const currentThread = useStore($currentThread);
  const isStreaming = useStore(sendMessageFx.pending);
  const { currentOrganization } = useStore(organizationsStore);
  const threads = useStore($filteredThreads);
  const isThreadHistoryView = useStore($threadHistoryView);
  const threadSearchQuery = useStore($threadSearchQuery);
  const pagination = useStore($threadPagination);
  const suggestedEntities = useStore($suggestedEntities);
  const quickQuestions = useStore($quickQuestions);

  const [inputMessage, setInputMessage] = useState('');
  const [editingMessageId, setEditingMessageId] = useState<string | null>(null);

  const [selectedEntities, setSelectedEntities] = useState<any[]>([]);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  // Use a dedicated ref for the messages container
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // Ref for throttling scroll calls
  const scrollTimeout = useRef<number | null>(null);

  // Scroll to bottom function (using containerRef)
  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  // Throttled scroll function to reduce calls while streaming
  const scrollToBottomThrottled = () => {
    if (scrollTimeout.current !== null) return;
    scrollTimeout.current = window.setTimeout(() => {
      scrollToBottom();
      scrollTimeout.current = null;
    }, 100);
  };

  // Effect: Trigger scrolling whenever messages update.
  useEffect(() => {
    scrollToBottomThrottled();
    // Cleanup any pending scroll timers if component unmounts or messages change.
    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
        scrollTimeout.current = null;
      }
    };
  }, [messages]);

  // Message content extraction
  const getMessageContent = (message: {
    content: { text: { value: string } }[];
  }) => {
    return message.content.map((part) => part.text.value).join('');
  };

  // Load messages when selecting a thread
  useEffect(() => {
    if (
      currentThread &&
      currentOrganization?.uniqueId &&
      !isThreadHistoryView
    ) {
      loadThreadMessagesFx({
        orgId: currentOrganization.uniqueId,
        threadId: currentThread.id,
      });
    }
  }, [currentThread, currentOrganization?.uniqueId, isThreadHistoryView]);

  // Message handling
  const handleSendMessage = () => {
    if (!inputMessage.trim() || !currentOrganization) return;

    // Create content array with only text for now
    const content = [
      {
        type: 'text',
        text: {
          value: inputMessage,
        },
      },
      ...selectedEntities.map((entity) => ({
        type: 'entity' as const,
        entity: {
          name: entity.displayText,
          entityId: entity.entityId,
          entityName: entity.entityName,
          entityType: entity.entityType,
        },
      })),
    ];

    // Send the message with the thread ID
    sendMessageFx({
      orgId: currentOrganization.uniqueId,
      threadId: currentThread?.id,
      content,
    });

    setInputMessage('');
    setSelectedEntities([]);
  };

  // File upload
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file || !onFileUpload) return;

    try {
      await onFileUpload(file, currentThread || undefined);
    } catch (error) {
      logger.error('File upload failed:', error);
    }
  };

  // Add search query
  const searchEntities = async ({ search }: { search: string }) => {
    try {
      const response = await queryGraphQlFx({
        query: `
          query($organizationId: Int!, $search: String!) {
            search(organizationId: $organizationId, search: $search, take: 10, skip: 0) {
              items {
                displayText
                entityName
                entityType
                entityId
              }
              totalCount
              pageInfo {
                hasNextPage
              }
            }
          }
        `,
        variables: {
          search,
        },
      });
      setData(response);
    } catch (error) {
      logger.error('Search failed:', error);
      setData(null);
    }
    setSearchLoading(false);
  };

  // Add search handler
  const handleSearch = async (query: string) => {
    setSearchLoading(true);

    await searchEntities({
      search: query,
    });
  };

  // Message editing
  const handleSaveEdit = (messageId: string) => {
    updateMessage({ id: messageId, newContent: inputMessage });
    setEditingMessageId(null);
    setInputMessage('');
  };

  // Reset pagination when entering thread history view or when search query changes
  useEffect(() => {
    if (isThreadHistoryView && currentOrganization?.uniqueId) {
      resetThreadPagination();
      searchThreadsFx({
        orgId: currentOrganization.uniqueId,
        offset: 0,
        limit: 10,
      });
    }
  }, [isThreadHistoryView, currentOrganization?.uniqueId, threadSearchQuery]);

  // Load more threads handler - memoized with useCallback
  const handleLoadMore = useCallback(() => {
    if (currentOrganization?.uniqueId && !pagination.loading) {
      searchThreadsFx({
        orgId: currentOrganization.uniqueId,
        offset: pagination.offset + pagination.limit,
        limit: pagination.limit,
      });
    }
  }, [
    currentOrganization?.uniqueId,
    pagination.offset,
    pagination.limit,
    pagination.loading,
  ]);

  // Create a memoized thread selection handler
  const handleThreadSelect = useCallback(
    (thread: Thread) => {
      selectThread(thread);
      if (currentOrganization?.uniqueId && thread.id) {
        loadThreadMessagesFx({
          orgId: currentOrganization.uniqueId,
          threadId: thread.id,
        });
      }
      toggleThreadHistory();
    },
    [currentOrganization?.uniqueId],
  );

  // Format date for thread list
  const formatThreadDate = (dateString: string) => {
    const date = new Date(dateString);
    return (
      date.toLocaleDateString() +
      ' ' +
      date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    );
  };

  // Handler functions to fix linter errors
  const handleToggleThreadHistory = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleThreadHistory();
  };

  const handleToggleAIChat = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleAIChat();
  };

  const handleCreateNewThread = (e: React.MouseEvent) => {
    e.preventDefault();
    createNewThread();
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchThreadQuery(e.target.value);
  };

  const handleMessageEditClick = (messageId: string) => (
    e: React.MouseEvent,
  ) => {
    e.preventDefault();
    setEditingMessageId(messageId);
  };

  const handleCopyMessageContent = (message: any) => (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(getMessageContent(message));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(e.target.value);
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputMessage(e.target.value);
  };

  const handleSetEditingNull = (e: React.MouseEvent) => {
    e.preventDefault();
    setEditingMessageId(null);
  };

  const handleSaveEditClick = (messageId: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    handleSaveEdit(messageId);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleQuickQuestionClick = (question: string) => (
    e: React.MouseEvent,
  ) => {
    e.preventDefault();
    setInputMessage(question);
  };

  // Effect to auto-select suggested entities when they change
  useEffect(() => {
    if (suggestedEntities.length > 0) {
      // Merge existing selected entities with new suggested entities (avoid duplicates)
      const mergedEntities = [...selectedEntities];

      suggestedEntities.forEach((suggestedEntity) => {
        // Check if entity is already selected
        const alreadySelected = selectedEntities.some(
          (entity) => entity.entityId === suggestedEntity.entityId,
        );

        if (!alreadySelected) {
          mergedEntities.push(suggestedEntity);
        }
      });

      setSelectedEntities(mergedEntities);

      // Optional: clear the suggested entities after they've been added
      // Comment this out if you want suggestions to persist
      // clearSuggestedEntities();
    }
  }, [suggestedEntities]);

  // Function to format entity label for display
  const formatEntityLabel = useCallback((option: any) => {
    return `${option.displayText} (${option.entityType})`;
  }, []);

  const renderMenuItemChildren = (option: any) => (
    <div>
      <strong>{option.displayText}</strong>
      <div className="text-muted">{option.entityType}</div>
    </div>
  );

  return (
    // Make the top-level card a flex container so that the header and body are separated.
    <div
      className="card w-100 d-flex flex-column ai-chat"
      style={{ height: '100%' }}
    >
      {/* Header */}
      <div className="card-header d-flex justify-content-between align-items-center ai-chat-header">
        <div
          className="d-flex flex-column overflow-hidden"
          style={{ minWidth: 0 }}
        >
          {isThreadHistoryView ? (
            <h5 className="mb-0">Thread History</h5>
          ) : (
            <h5 className="mb-0 text-truncate">
              {currentThread ? currentThread.title || 'New Chat' : 'New Chat'}
            </h5>
          )}
          {currentContext && !isThreadHistoryView && (
            <small className="text-muted text-truncate">
              {currentContext.type}: {currentContext.id}
            </small>
          )}
        </div>
        <div className="d-flex align-items-center gap-2">
          {isThreadHistoryView ? (
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm flex-shrink-0"
              onClick={handleToggleThreadHistory}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm flex-shrink-0"
                onClick={handleToggleThreadHistory}
              >
                <FontAwesomeIcon icon={faHistory} className="mr-1" />
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm flex-shrink-0"
                onClick={handleCreateNewThread}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
              </button>
            </>
          )}
          <button
            type="button"
            className="btn btn-outline-default btn-sm flex-shrink-0 ml-2"
            onClick={handleToggleAIChat}
          >
            <FontAwesomeIcon icon={faTimes} className="mr-1" />
          </button>
        </div>
      </div>

      {/* Body (flex column with only the messages area scrolling) */}
      <div className="card-body d-flex flex-column flex-grow-1 p-0 ai-chat-body">
        {isThreadHistoryView ? (
          // Thread History View
          <div className="d-flex flex-column h-100">
            {/* Search input */}
            <div className="p-3 border-bottom">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search threads..."
                  value={threadSearchQuery}
                  onChange={handleSearchInputChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </div>
              </div>
            </div>

            {/* Thread list */}
            <div className="flex-grow-1 overflow-auto">
              {threads.length === 0 ? (
                <div className="p-4 text-center text-muted">
                  {threadSearchQuery
                    ? 'No matching threads found.'
                    : pagination.loading
                    ? 'Loading threads...'
                    : 'No threads yet.'}
                </div>
              ) : (
                <div className="list-group list-group-flush">
                  {threads &&
                    threads.map &&
                    threads?.map((thread) => (
                      <ThreadItem
                        key={thread.id}
                        thread={thread}
                        onSelect={handleThreadSelect}
                      />
                    ))}

                  {/* Load More button */}
                  {pagination.has_more && (
                    <div className="px-3">
                      <LoadMoreButton
                        onClick={handleLoadMore}
                        loading={pagination.loading}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          // Chat View
          <>
            {/* Scrollable Messages Container */}
            <div
              ref={scrollContainerRef}
              className="flex-grow-1 overflow-auto p-3 ai-chat-messages"
            >
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-2 ${
                    message.sender_type === 'user' ? 'ml-auto' : 'mr-auto'
                  }`}
                  style={{ maxWidth: '100%' }}
                >
                  {editingMessageId === message.id ? (
                    <div className="border border-primary rounded p-2 bg-white">
                      <textarea
                        className="form-control"
                        value={inputMessage}
                        onChange={handleTextareaChange}
                        rows={3}
                        autoFocus={true}
                      />
                      <div className="d-flex justify-content-end mt-2">
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm mr-2"
                          onClick={handleSetEditingNull}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={handleSaveEditClick(message.id)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`p-3 rounded ${
                        message.sender_type === 'user'
                          ? 'bg-primary text-white'
                          : 'bg-light'
                      }`}
                    >
                      {message.sender_type === 'user' ? (
                        <div className="gap-1">
                          <div className="d-flex flex-wrap gap-1">
                            {message.content.map((part, index) => {
                              if (part.type === 'entity') {
                                return (
                                  <span
                                    key={index}
                                    className="badge bg-light text-dark d-flex align-items-center"
                                    style={{ fontSize: '0.8em' }}
                                  >
                                    {part.entity.name}
                                    <small className="text-muted ml-1">
                                      ({part.entity.entityType})
                                    </small>
                                  </span>
                                );
                              }
                            })}
                          </div>
                          <div className="d-flex flex-wrap gap-1">
                            {message.content.map((part, index) => {
                              if (part.type === 'text') {
                                return (
                                  <ReactMarkdown
                                    key={index}
                                    linkTarget="_blank"
                                  >
                                    {part.text?.value}
                                  </ReactMarkdown>
                                );
                              }
                            })}
                          </div>
                        </div>
                      ) : (
                        <ReactMarkdown linkTarget="_blank">
                          {getMessageContent(message)}
                        </ReactMarkdown>
                      )}
                    </div>
                  )}

                  {/* Message Actions */}
                  <div className="d-flex align-items-center mt-1 gap-2">
                    {message.sender_type === 'user' ? (
                      <MessageEditButton
                        messageId={message.id}
                        onEdit={setEditingMessageId}
                      />
                    ) : (
                      <MessageCopyButton
                        message={message}
                        getContent={getMessageContent}
                      />
                    )}
                    <small className="text-muted ms-auto">
                      {new Date(message.created_at).toLocaleTimeString()}
                    </small>
                  </div>
                </div>
              ))}
            </div>

            {/* Quick Questions (fixed at bottom) */}
            {quickQuestions.length > 0 && (
              <div className="border-top p-3 ai-chat-quick-questions">
                <div className="d-flex flex-wrap gap-2">
                  {quickQuestions.map((question, i) => (
                    <QuickQuestionButton
                      key={i}
                      question={question}
                      onClick={setInputMessage}
                    />
                  ))}
                </div>
              </div>
            )}

            {/* Input Area (fixed at bottom) */}
            <div className="border-top p-3 ai-chat-input">
              <div className="d-flex align-items-start gap-2 mb-1">
                <AsyncTypeahead
                  id="entity-search"
                  className="flex-grow-1 expanding-typeahead"
                  inputProps={{
                    style: {
                      minHeight: '38px',
                      height: 'auto',
                    },
                  }}
                  isLoading={isSearchLoading}
                  options={data?.search?.items || []}
                  labelKey={formatEntityLabel}
                  dropup={true}
                  minLength={2}
                  onSearch={handleSearch}
                  onChange={setSelectedEntities}
                  multiple={true}
                  selected={selectedEntities}
                  placeholder="Search entities..."
                  renderMenuItemChildren={renderMenuItemChildren}
                />
              </div>
              <div className="d-flex align-items-start gap-2">
                <input
                  type="text"
                  className="form-control mr-2"
                  placeholder="Type your message..."
                  value={inputMessage}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSendMessage}
                  disabled={isStreaming || !inputMessage.trim()}
                >
                  {isStreaming ? (
                    <FontAwesomeIcon icon={faSpinner} spin={true} />
                  ) : (
                    <FontAwesomeIcon icon={faPaperPlane} />
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AIChat;
