import React, { useEffect, useMemo } from 'react';
import { InternalLayout } from '../../common/components/internal-layout/internal-layout.component';
import { AppModuleEdit } from '../components/appModule-edit.component';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { INTERNAL_PATH } from '../../../app.router';
import {
  APPMODULES_LIST_PATH,
  APPMODULE_CREATE_PATH,
  APPMODULE_EDIT_PATH,
} from '../appModules.route';
import { Helmet } from 'react-helmet';
import { organizationsStore } from '../../organization/organization.store';
import { CREATE_APPMODULE_LINK_KEY } from '../appModules.service';
import { userHas } from '../../auth/auth.store';
import { useStore } from 'effector-react';
import { connectToSSE } from '../../aiChat/aiChat.store';

export type AppModuleScreenProps = { pageTitle: string; group: string };
export type AppModuleRouteParams = {
  organizationId: string;
  appModuleId: string | string;
};

export const AppModuleScreen = ({ pageTitle, group }: AppModuleScreenProps) => {
  const navigate = useNavigate();

  const { organizationId, appModuleId }: AppModuleRouteParams = useParams();

  // load current organization
  const { currentOrganization } = useStore(organizationsStore);

  // connect to SSE
  useEffect(() => {
    if (currentOrganization && currentOrganization.uniqueId) {
      connectToSSE(currentOrganization.uniqueId);
    }
  }, [currentOrganization]);

  const createPath = useMemo(
    () =>
      generatePath(INTERNAL_PATH + APPMODULE_CREATE_PATH, {
        organizationId,
      }),
    [organizationId],
  );

  const goToAppModule = useMemo(
    () => ({ appModuleId }: { appModuleId: string }) => {
      const path = generatePath(INTERNAL_PATH + APPMODULE_EDIT_PATH, {
        organizationId,
        appModuleId,
      });
      navigate(path);
    },
    [navigate, organizationId],
  );

  const closeScreen = useMemo(
    () => () => {
      // check if we can go back
      if (navigate.length > 2) {
        navigate(-1);
      } else {
        const path = generatePath(INTERNAL_PATH + APPMODULES_LIST_PATH, {
          organizationId: currentOrganization.organizationId,
        });
        navigate(path);
      }
    },
    [navigate, currentOrganization],
  );

  const appModuleIdMemo = useMemo(
    () => (appModuleId !== 'create' ? appModuleId : null),
    [appModuleId],
  );

  return (
    <InternalLayout
      group={group}
      title={'AppModule'}
      createPath={userHas(CREATE_APPMODULE_LINK_KEY) ? createPath : null}
      createLabel={'AppModule'}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppModuleEdit
        appModuleId={appModuleIdMemo}
        onCancel={closeScreen}
        onAppModuleCreated={goToAppModule}
        onDelete={closeScreen}
      />
    </InternalLayout>
  );
};
